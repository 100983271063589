import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image"

import IntroductionLayout from "../../container/IntroductionLayout"

const SubTitle = styled.h2`
  color: black;
  display: block;
  margin-bottom: 90px;
  text-align: center;
  
  @media screen and (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 40px;
  }
`;


const Description = styled.div`
  color: black;
  display: block;
  margin-bottom: 20px;
  
  > p {
    margin-bottom: 10px;
  }
`;

const MainImage = styled(GatsbyImage)`
  margin-bottom: 50px;
  margin-left: 50px;
  width: 500px;
  float: right;
  @media screen and (max-width: 768px) {
    width: 320px;
    margin: 0 calc(50% - 160px) 30px;
  }
`

const SignContainer = styled.div`
  display: block;

  > div {
    text-align: right;
  }
`;

const Sign = styled(GatsbyImage)`
  margin-left: 20px;
  margin-bottom: 0;
  float: right;
`;

function CEOIntroduce () {
  const { site, image, sign } = useStaticQuery(graphql`
      query {
          site {
              siteMetadata {
                  email
              }
          }
          image: file(relativePath: { eq: "sukrae.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          sign: file(relativePath: { eq: "main_company_sign.png" }) {
              childImageSharp {
                  fixed(width: 300) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
      }
  `)

  return (
    <IntroductionLayout title="CEO 인사말">
      <SubTitle>"최상의 안전을 위한 발전하는 기술"</SubTitle>
      <Description>
        <MainImage fluid={image.childImageSharp.fluid} />
        <p>
          동아특수건설은 1986년 우리나라의 사면안정 분야의 효시로 출발하여 건설현장에서 수 백여 프로젝트를 수행했습니다.
          사면안정의 역사를 개척한다는 자부심으로 신기술 개발을 향한 도전과 아낌없는 투자를 바탕으로
          사면안정 전문기업으로 성장해 왔습니다.
          어제보다 오늘 더 발전하기 위해 도전하는 기업입니다.
        </p>
        <p>
          최고의 품질·안전·환경경영체제를 지향하며 경제적이고 효율적인 지식경영 시스템을 구축하고
          원가절감과 체질개선 노력을 통한 경영효율성 향상에 모든 노하우를 집중하고 있습니다.이러한 바탕에서 동아특수건설은 그동안 쌓아온 기술과 신뢰를 키워 스스로와의 약속, 고객과의 약속,
          사회와의 약속을 지키는 기업이 되도록 꾸준히 노력하고 있습니다.동아특수건설은 지금의 상황에 머무는 것보다 최신 장비와 공법으로 기술력을 향상시키고,
          숙련된 기량과 기술로 제품의 우수성을 발전시킵니다.
        </p>
        <p>
          동아특수건설은 고객을 한번 더 생각하여 프로젝트를 수행합니다.
          최고의 품질과 완벽한 서비스로 고객을 만족시키는 가장 믿을 수 있는 사업파트너가 될 것을 약속 드립니다.
        </p>
        <SignContainer>
          <Sign fixed={sign.childImageSharp.fixed} />
          <div>
            대표이사&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;심 석 래
          </div>
          <div>
            { site.siteMetadata.email }
          </div>
        </SignContainer>
      </Description>
    </IntroductionLayout>
  )
}

export default CEOIntroduce